import React from "react";
import PropTypes from "prop-types";
import Button from "components/UI/button";

import "styles/reset-password.scss";
import Input from "./input";
import useAuthForm from "../../hooks/useAuthForm";
import useValidation from "../../hooks/useValidation";

const ResetPassword = () => {

    const {
        isShowPassword,
        setIsShowPassword,
        setResetPasswordFormData,
        resetPasswordFormData,
        sendNewPassword,
    } = useAuthForm({ });

    const { validation } = useValidation();

    const params = new URL(document.location).searchParams;
    const id = params.get("id");
    const token = params.get("token");

    const handleSubmit = (e) => {
        sendNewPassword(e, id, token);
    }

    return (
        <div className="reset-password-wrapper">
            <>
                <h2 className="auth__heading typography__h2">
                    Nieuw wachtwoord
                </h2>
                <p className="auth__description">
                    vul je nieuwe wachtwoord in
                </p>
                <form onSubmit={handleSubmit} className="auth__form">
                    <Input
                        type="password"
                        placeholder="nieuwe wachtwoord"
                        name="new_password"
                        value={resetPasswordFormData.new_password}
                        onChange={setResetPasswordFormData}
                        validation={[validation.password]}
                        message="Password must contain at least 8 characters, 1 number, 1 uppercase character and 1 uppercase character"
                        isShowPassword={isShowPassword}
                        setIsShowPassword={setIsShowPassword}
                        isPasswordInput
                    />
                    <Button value="Verzenden" type="dark" isArrowShow isSubmit/>
                </form>
            </>
        </div>
    );
};

ResetPassword.propTypes = {
    afterAuth: PropTypes.func,
    children: PropTypes.node
}

export default ResetPassword;
