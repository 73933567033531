import React, { useEffect, useState } from "react";

import OpenIcon from "images/svg/password-open-eye.svg";
import CloseIcon from "images/svg/password-close-eye.svg";
import CheckboxIcon from "images/svg/inline/checkbox.inline.svg";

import Image from "components/UI/image";
import axios from "axios";

import useValidation from "hooks/useValidation";
import { t } from "utils/translate";

import "styles/input.scss";

const Input = (props) => {
    const { validation: validationMethod } = useValidation();

    const {
        type = "text",
        placeholder = "",
        id = "",
        name = "",
        onChange,
        value = "",
        validation = [validationMethod.notEmpty],
        message = "Voer een geldige " + placeholder + " in.",
        isShowPassword,
        setIsShowPassword,
        isPasswordInput,
        checked,
        required,
        className,
        isSingleInput = false,
        isFormTriggered = false,
        disabled = false,
        isAutocomplete = false,
        changedCountry,
        setForcedValue,
        autocomplete,
        isAddressInUse, 
        setIsAddressInUse
    } = props;

    const [isTouched, setIsTouched] = useState(false);
    const [suggestion, setSuggestion] = useState([]);
    const [isShowSuggesion, setIsShowSuggesion] = useState(false);
    const [isValid, setIsValid] = useState(true);

    const getAddressSuggestion = async (input) => {
        try {
            const response = await axios.get("/api/google/autocomplete", {
                params: {
                    input: input
                }
            })
            setSuggestion(response.data)
        }
        catch (e) {
            setSuggestion([])
        }
    }

    const changeHandler = (event, name, callback) => {
        if (type === "checkbox") {
            callback((prev) => !prev);
        } else {

            if (isAutocomplete) {
                callback((prev) => {
                    return { ...prev, [name]: event.target.value };
                });
            }

            if (isSingleInput) {
                return callback(event.target.value);
            }

            callback((prev) => {
                return { ...prev, [name]: event.target.value };
            });
        }
    };

    const onSuggestionClick = (id) => {
        axios.post("/api/google/autocomplete", {
            place_id: id
        }).then((response) => {
                const addressData = response?.data?.result?.address_components;

                const postcode = addressData.find((el) => el.types.includes("postal_code"))?.long_name;
                const street = addressData.find((el) => el.types.includes("route"))?.long_name;
                const country = addressData.find((el) => el.types.includes("country"))?.short_name;
                const city = addressData.find((el) => el.types.includes("locality"))?.long_name;
                const building = addressData.find((el) => el.types.includes("street_number"))?.long_name;

                
                if (postcode) {
                    changeHandler({ target: { value: postcode } }, "postal_code", onChange);
                }
                
                if (street) {
                    changeHandler({ target: { value: street } }, "address1", onChange);
                }
                
                if (building) {
                    changeHandler({ target: { value: building } }, "house_number", onChange);
                }

                if (city) {
                    changeHandler({ target: { value: city } }, "city", onChange);
                }

                if (country) {
                    changedCountry && changedCountry({ target: { value: country } });
                    setForcedValue && setForcedValue(country);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const replaceSubstringWithBold = (text, searchString) => {

        if(!searchString){
            setSuggestion([])
            return text
        }

        const startIndex = text.toLowerCase().indexOf(searchString.toLowerCase());
      
        if (startIndex === -1) {
          return text;
        }
      
        const endIndex = startIndex + searchString.length;
        const beforeSubstring = text.substring(0, startIndex);
        const highlightedSubstring = text.substring(startIndex, endIndex);
        const afterSubstring = text.substring(endIndex);
      
        return (
          <span>
            {beforeSubstring}
            <b>{highlightedSubstring}</b>
            {replaceSubstringWithBold(afterSubstring, searchString)}
          </span>
        );
      };

    useEffect(() => {
        const timer = setTimeout(() => {
            if ((value || isTouched) && isAutocomplete) {
                getAddressSuggestion(value)
            }
        }, 250);

        return () => {
            clearTimeout(timer);
        };
    }, [value]);

    useEffect(() => {
        if (isFormTriggered) {
            setIsTouched(true);
        }
    }, [isFormTriggered, validation]);

    useEffect(() => {
        setIsValid(validation.every((rule) => rule(value)))
    }, [value, validation]);

    const isShowError = (!isValid && (isTouched || isFormTriggered)) || isAddressInUse

    if (type === "checkbox") {
        return (
            <>
                <label className={`form__checkbox ${className ? className : ""}`}>
                    <input
                        className={`${value ? "filled" : ""} ${isShowError ? "invalid" : ""}`}
                        type="checkbox"
                        checked={checked}
                        onChange={(e) => onChange && changeHandler(e, name, onChange)}
                        data-is-valid={isValid}
                        required={required}
                    />
                    <div className="checkbox">
                        <CheckboxIcon />
                    </div>
                    <span>{placeholder + (required ? "*" : "")}</span>
                </label>
                <p className={`error-message typography__small--inter ${isShowError ? "" : "hidden"}`}>
                    {message}
                </p>
            </>
        );
    }

    return (
        <div className={`input-wrapper ${className ? className : ""}`}>
            <label className="input-label" htmlFor={name}>
                <span className="input-container">
                    <input
                        className={`${value ? 'filled' : ''}${isShowError ? ' invalid' : ''}${isPasswordInput ? ' password-input' : ''}`}
                        type={isShowPassword ? 'text' : type}
                        id={id || name}
                        name={name}
                        onChange={(e) => {
                            onChange && changeHandler(e, name, onChange)
                            setIsAddressInUse && setIsAddressInUse(false)
                        }}
                        onFocus={() => { setIsShowSuggesion(true) }}
                        onBlur={(e) => {
                            setIsTouched(true)
                            setTimeout(() => {
                                setIsShowSuggesion(false)
                            }, 250)
                        }}
                        value={value}
                        data-is-valid={isValid}
                        required={required}
                        disabled={disabled}
                        input-placeholder={placeholder}
                        autoComplete={autocomplete || "off"}
                    />
                </span>
                <p className={`placeholder typography__p ${value ? "smaller" : ""}`}>
                    {t(placeholder) + (required ? "*" : "")}
                </p>
                {isPasswordInput && (
                    <Image
                        onClick={() => setIsShowPassword((prev) => !prev)}
                        className="password-visibility"
                        src={isShowPassword ? CloseIcon : OpenIcon}
                    />
                )}
            </label>
            <p className={`error-message typography__small--inter ${isShowError ? "" : "hidden"}`}>
                {isAddressInUse? "Dit e-mail adres is al in gebruik." :message}
            </p>
            {
                isShowSuggesion && suggestion.length > 0 &&
                <ul className="suggestion">
                    {
                        suggestion.map(item => {
                            const replacedText = replaceSubstringWithBold(item.description, value);  
                            return <li key={item.place_id} onClick={() => onSuggestionClick(item.place_id)}>{replacedText}</li>
                        })
                    }
                </ul>
            }
        </div>
    );
};

export default Input;
