import { useState } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { useCustomer } from "context/customerContext";
import useValidation from "hooks/useValidation";
import { navigate } from "gatsby";
import { useWindow } from "context/windowContext";

const useAuthForm = (props) => {
    const { afterAuth } = props;
    const { validation } = useValidation();


    const { setCustomer } = useCustomer();
    const { document, localStorage } = useWindow();
    const [isShowPassword, setIsShowPassword] = useState(false);

    const [isSuccessResetPassword, setIsSuccessResetPassword] = useState(false);

    const [signInFormData, setSignInFormData] = useState({
        email: "",
        password: "",
    });

    const [resetPasswordFormData, setResetPasswordFormData] = useState({
        email: "",
        new_password: ""
    });

    const [isTermsChecked, setIsTermsChecked] = useState(null);
    const [isFormTriggered, setIsFormTriggered] = useState(false);
    // const [isWrongCredentials, setIsWrongCredentials] = useState(false)
    const [isAddressInUse, setIsAddressInUse] = useState(false)

    const [signUpFormData, setSignUpFormData] = useState({
        first_name: "",
        last_name: "",
        email: "",
        password: "",
    });

    const loginCustomer = async (e) => {

        const signInFormData = {
            email: e.values.email,
            password: e.values.password,
        }

        if (!validation.email(signInFormData.email) || !validation.password(signInFormData.password)) {
            setIsFormTriggered(true)
            return
        }

        try {
            const promise = axios
                .post("/api/v3/customers/validate-credentials", signInFormData)
                .then(async (response) => {
                    if (response.status === 200) {
                        if (!response?.data?.is_valid) {
                            let message = Array.from(document.querySelectorAll(".error-message"))
                            message.forEach(m => {
                                m.innerText = 'Voer geldige inloggegevens in'
                                m.className = 'error-message typography__small--inter trigger'
                            })
                            return;
                          }
                        setCustomer(response.data.customer_id);
                        afterAuth && afterAuth();
                    }
                });

            console.log("Signing in...")

            // await toast.promise(promise, {
            //     pending: "Signing in...",
            // });
        } catch (error) {
            console.error(error);
        }
    };

    const createCustomer = async (e) => {
        e.preventDefault();
        setIsFormTriggered(true)

        const inputs = Array.from(e.target.querySelectorAll("input"));
        const isFormValid = inputs.every(
            (input) => input.getAttribute("data-is-valid") !== "false"
        );

        if (!isFormValid) {
            return;
        }

        if (!isTermsChecked) {
            setIsTermsChecked(false);
            return;
        }

        try {
            const promise = axios
                .post('/api/v3/customers', signUpFormData)
                .then(response => {
                    if (response.status === 200) {
                        setCustomer(response.data[0].id);

                        afterAuth && afterAuth();
                    }
                }).catch(e => {

                    console.log(e)
                    let message = Array.from(document.getElementById("email"))
                    message.forEach(m => {
                        m.innerText = 'Dit e-mail adres is al in gebruik.'
                        m.className = 'error-message typography__small--inter'
                    })
                    setIsAddressInUse(true);
                })

            console.log("Creating account...");

            // await toast.promise(promise, {
            //     pending: "Creating account...",
            // });
        } catch (error) {
            console.error(error);
        }
    };

    const handleResetPassword = async (e) => {
        e.preventDefault();
        const inputs = Array.from(e.target.querySelectorAll("input"));
        const isFormValid = inputs.every(
            (input) => input.getAttribute("data-is-valid") !== "false"
        );
        if (!isFormValid) {
            return;
        }
        try {
            const promise = axios
                .get(`/api/v3/customers/reset-password?email:in=${resetPasswordFormData.email}`)
                .then((response) => {
                    if (response.status === 200) {
                        setIsSuccessResetPassword(true);
                    }
                });

            console.log("Reset password...");

            // await toast.promise(promise, {
            //     pending: "Reset password...",
            // });
        } catch (error) {
            console.error(error);
        }
    };

    const sendNewPassword = async (e, id, token) => {
        e.preventDefault();
        const inputs = Array.from(e.target.querySelectorAll("input"));
        const isFormValid = inputs.every(
            (input) => input.getAttribute("data-is-valid") !== "false"
        );
        if (!isFormValid) {
            return;
        }
        try {
            const promise = axios
                .put(`/api/v3/customers/reset-password`, [{
                    "id": id,
                    "token": token,
                    "authentication": {
                        "new_password": resetPasswordFormData.new_password
                    }
                }])
                .then((response) => {
                    if (response.status === 200 && response.data !== "already-used") {
                        setTimeout(() => {
                            navigate("/?action=openLoginForm");
                        }, 2000);
                    }
                });

            console.log("Sent new password...");

            // await toast.promise(promise, {
            //     pending: "Sent new password...",
            // });
        } catch (error) {
            console.error(error);
        }
    };

    return {
        signInFormData,
        setSignInFormData,
        signUpFormData,
        setSignUpFormData,
        createCustomer,
        loginCustomer,
        isShowPassword,
        setIsShowPassword,
        isTermsChecked,
        setIsTermsChecked,
        handleResetPassword,
        setResetPasswordFormData,
        resetPasswordFormData,
        sendNewPassword,
        isSuccessResetPassword,
        setIsSuccessResetPassword,
        isFormTriggered,
        // isWrongCredentials,
        // setIsWrongCredentials,
        isAddressInUse, 
        setIsAddressInUse
    };
};

useAuthForm.propTypes = {
    afterAuth: PropTypes.func,
};

export default useAuthForm;
