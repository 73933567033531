import React from "react";
import Main from "components/Layout/main";
import ResetPassword from "components/UI/resetPassword";
import StaticPageMeta from "../components/UI/staticPageMeta";

const ResetPasswordPage = (props) => {
    const gtmData = {
        page: {
            title: "ResetPasswordPage",
            type: "Static",
        },
    };

    return (
        <>
            <StaticPageMeta pageName="Reset password" />
            <Main className="blog" gtmData={gtmData}>
                <ResetPassword />
            </Main>
        </>
    );
};
export default ResetPasswordPage;
